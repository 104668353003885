.container {
  box-shadow: 0px 17px 40px 4px rgba(112, 144, 176, 0.11);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 250px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffffff;
  transition: all 0.5s;
  z-index: 99;
  > .head {
    padding: 1.5rem 0.5rem;
    text-align: center;
    cursor: pointer;
    > span:nth-of-type(1) {
      display: inline-block;
      height: 30px;
      width: 30px;
      border-radius: 30px;
      background: linear-gradient(270deg, #ff47aa 0%, #aa56ff 30%, #418dff 80%), #02183a;
      vertical-align: text-bottom;
      margin-right: 10px;
      margin-left: -18px;
    }
    > span:nth-of-type(2) {
      font-weight: bolder;
      font-size: 1.7rem;
      font-weight: bolder;
      font-family: InterWeight;
    }
  }
  > .line {
    width: 100%;
    height: 1px;
    background-color: #eceff8;
  }
  > .tabList {
    padding: 1rem 0.8rem;
    > .tabItem {
      position: relative;
      margin-bottom: 1.3rem;
      //   background-color: #f1f7fb;
      color: #000000;
      padding: 0.5rem;
      border-radius: 5px;
      font-weight: 500;
      cursor: pointer;
      > .add {
        color: #ffffff;
        background-color: #4f94fe;
        display: inline-block;
        position: absolute;
        height: 20px;
        width: 20px;
        line-height: 18px;
        text-align: center;
        border-radius: 20px;
        right: 12px;
        top: 10px;
        cursor: pointer;
      }
      .lock {
        position: absolute;
        right: 5px;
        top: 12px;
        font-size: 12px;
        color: #ff47aa;
        background-color: #ffe5f3;
        padding: 1px 5px;
        border-radius: 15px;
        cursor: pointer;
        img {
          height: 10px;
          width: 10px;
          margin-right: 2px;
        }
      }
    }
    > .actice {
      color: #7db1fd;
      background-color: #f1f7fb;
      font-weight: 500;
    }
    .name {
      vertical-align: text-top;
    }
    .icon {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  > .bottom {
    position: absolute;
    bottom: 0.5rem;
    padding: 0.8rem 1.2rem;
    width: 100%;
    > div:nth-of-type(1) {
      background-color: #f1f7fb;
      border-radius: 10px;
      // height: 5rem;
      padding: 10px;
      > .uif {
        display: flex;
        align-items: center;
        > div:nth-of-type(1) {
          height: 2rem;
          width: 2rem;
          background-color: #d9d9d9;
          text-align: center;
          line-height: 2rem;
          border-radius: 2rem;
          color: #ffffff;
          margin-right: 10px;
          img {
            height: 2rem;
            width: 2rem;
            border-radius: 2rem;
          }
        }
        > div:nth-of-type(2) {
          > div:nth-of-type(1) {
            color: #02183a;
            width: 130px;
            overflow: hidden;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          > div:nth-of-type(2) {
            color: #6c7074;
            font-size: 0.8rem;
            line-height: 0.8rem;
            overflow: hidden;
            width: 130px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      > .action {
        > .ac1 {
          display: flex;
          justify-content: space-between;
          margin-top: 0.8rem;
          > div:nth-of-type(1) {
            background-color: #ffffff;
            padding: 0.1rem 0.5rem 0.1rem 0.5rem;
            font-size: 0.8rem;
            border-radius: 15px;
            cursor: pointer;
            position: relative;
            width: 90px;
            img {
              vertical-align: sub;
              margin-right: 5px;
            }
            span:nth-of-type(1) {
              font-size: 12px;
              position: absolute;
              width: fit-content;
              right: 10px;
              top: 3px;
            }
            span:nth-of-type(2) {
              margin-left: 5px;
              background-color: #ebf4ff;
              color: #2e83fa;
              font-size: 10px;
              padding: 2px 8px;
              border-radius: 8px;
              position: absolute;
              width: fit-content;
              right: 3px;
              // vertical-align: bottom;
            }
          }
          > div:nth-of-type(2) {
            background-color: #ffffff;
            width: 1.5rem;
            text-align: center;
            border-radius: 1.5rem;
            cursor: pointer;
            img {
              height: 0.7rem;
            }
            &:hover {
              background-color: #c3c3c7;
            }
          }
        }
        > .ac2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0.8rem;
          > div:nth-of-type(1) {
            background-color: #ffffff;
            padding: 0.1rem 0.8rem;
            font-size: 0.8rem;
            border-radius: 15px;

            cursor: pointer;
            color: #f84c4d;
            img {
              vertical-align: middle;
              margin-right: 5px;
              height: 12px;
            }
            span {
              vertical-align: text-top;
            }
          }
          > div:nth-of-type(2) {
            background-color: #ffffff;
            width: 1.3rem;
            text-align: center;
            height: 1.3rem;
            border-radius: 1.5rem;
            vertical-align: middle;
            cursor: pointer;
            img {
              height: 0.7rem;
              margin-left: 1px;
            }
            &:hover {
              background-color: #c3c3c7;
            }
          }
        }
        > .ac3 {
          display: flex;
          justify-content: space-between;
          margin-top: 0.8rem;
          gap: 1rem;
          > div {
            width: 50%;
            cursor: pointer;
          }
          > div:nth-of-type(1) {
            background-color: #ffffff;
            padding: 0.1rem 0.8rem;
            font-size: 0.8rem;
            border-radius: 15px;
            position: relative;

            cursor: pointer;
            img {
              vertical-align: middle;
              margin-right: 5px;
              height: 12px;
            }
            span {
              font-size: 12px;
              border-radius: 8px;
              position: absolute;
              width: fit-content;
              right: 10px;
              top: 3px;
            }
          }
          > div:nth-of-type(2) {
            font-size: 0.8rem;
            color: #ffffff;
            text-align: center;
            border-radius: 1rem;
            line-height: 1.4rem;
            background: linear-gradient(270deg, #ff47aa 0%, #aa56ff 30%, #418dff 80%);
          }
        }
      }
    }
    > div:nth-of-type(2) {
      margin-top: 1.2rem;
      display: flex;
      justify-content: space-between;
      > div:nth-of-type(1) {
        display: flex;
        > div {
          height: 25px;
          width: 25px;
          border-radius: 25px;
          border: 1px solid #e0e5f2;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            border-color: var(--app-primary-color);
          }
          img {
            vertical-align: sub;
            margin-left: 1px;
          }
        }
        > div:nth-of-type(1) {
          margin-right: 5px;
        }
      }
      > div:nth-of-type(2) {
        height: 25px;
        width: 25px;
        border-radius: 25px;
        border: 1px solid #e0e5f2;
        text-align: center;
        cursor: pointer;
        &:hover {
          border-color: var(--app-primary-color);
        }
        img {
          vertical-align: sub;
          margin-left: 1px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    left: -100%;
  }
}

